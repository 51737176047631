import React, { useContext } from 'react'
import { Layout, Breadcrumb } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import style from './index.module.less'
import SideMenu from './SideMenu'
import routerConfig from '@/router/config'
import { pathToRegexp } from 'path-to-regexp'
import { useRequest } from 'ahooks'
import Right from './Right'
import GlobalFooter from '../GlobalFooter'
import PageLoading from '@/components/PageLoading'

const { Header, Content } = Layout

const urlToList = url => {
  const urllist = url.split('/').filter(i => i)
  return urllist.map((urlItem, index) => `/${urllist.slice(0, index + 1).join('/')}`)
}

const getBreadcrumbNameMap = menuData => {
  if (!menuData) {
    return {}
  }
  const routerMap = {}

  const flattenMenuData = data => {
    data.forEach(item => {
      if (item.routes) {
        flattenMenuData(item.routes)
      }
      // Reduce memory usage
      if (item.name) routerMap[item.path] = item.name
    })
  }
  flattenMenuData(menuData)
  return routerMap
}

const getBreadcrumb = (breadcrumbNameMap, url) => {
  let breadcrumb = breadcrumbNameMap[url]
  if (!breadcrumb) {
    Object.keys(breadcrumbNameMap).forEach(item => {
      if (pathToRegexp(item).test(url)) {
        breadcrumb = breadcrumbNameMap[item]
      }
    })
  }
  return breadcrumb || null
}

const conversionFromLocation = (pathname, breadcrumbNameMap) => {
  // Convert the url to an array
  const pathSnippets = urlToList(pathname)
  // Loop data mosaic routing
  const extraBreadcrumbItems = pathSnippets
    .map(url => {
      const currentBreadcrumb = getBreadcrumb(breadcrumbNameMap, url)

      // TODO 这里是什么意思
      // if (currentBreadcrumb.inherited) {
      //   return null
      // }

      // return currentBreadcrumb || {}
      return currentBreadcrumb
    })
    .filter(item => item !== null)
  // Add home breadcrumbs to your head if defined
  // extraBreadcrumbItems.unshift('首页')
  return extraBreadcrumbItems
}

// 基础布局每次加载直接请求用户数据，如果401 会直接跳转到登陆页面，如果成功则存储用户数据
export default function BasicLayout({ route, children, ...rest }) {
  const { error, loading } = useRequest('/users/me/', {
    onSuccess: data => {
      localStorage.setItem('user', JSON.stringify(data))
      localStorage.setItem('tenant', data.tenantId)
    },
  })
  // localStorage.setItem(
  //   'user',
  //   JSON.stringify({ name: 'cm', mobile: '18674009822', permissions: [] })
  // )

  // const { setUser } = useContext(UserContext)

  const breadcrumbNameMap = getBreadcrumbNameMap(routerConfig)
  const location = useLocation()

  // 获取当前path的breadcrumb
  const tmp = conversionFromLocation(location.pathname, breadcrumbNameMap)
  let a = []
  const b = tmp.map(item => <Breadcrumb.Item key="home">{item}</Breadcrumb.Item>)

  // console.log('route=', route)

  a = [
    <Breadcrumb.Item key="home">
      <Link to={route.path}>首页</Link>
    </Breadcrumb.Item>,
  ].concat(b)

  // 请求无错误时，加载页面
  if (!loading && !error)
    return (
      <div>
        {
          <Layout className={style.container}>
            {/* 左侧 */}
            <SideMenu routes={route.routes} appTitle={route.title} appRoute={route.path} />

            {/* 右侧 */}
            <Layout>
              {/* 顶部 */}
              <Header className={style.header}>
                {/* TODO 路由未匹配到时，这里会报错 */}
                <Breadcrumb>{a}</Breadcrumb>
                <Right />
              </Header>

              {/* 内容区 */}
              <Content className={style.content}>
                <div className={style.appContainer}>
                  {/* <Breadcrumb>{a}</Breadcrumb> */}
                  {children}
                </div>
              </Content>
              {/* 底部 */}
              <GlobalFooter />
            </Layout>
          </Layout>
        }
      </div>
    )

  if (loading) return <PageLoading />
  else return null
}

import axios from 'axios'
import { notification } from 'antd'
import { history } from '@/router'

const request = axios.create({
  baseURL: '/api',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
})

// request interceptor
// 不全局提示错误的白名单
const errorWhiteList = ['/token/']

request.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    const tenant = localStorage.getItem('tenant')

    if (token) config.headers['Authorization'] = `Bearer ${token}`
    //     if (tenant) config.headers['X-TENANT'] = tenant
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// response interceptor
request.interceptors.response.use(
  response => {
    if (response.status >= 200 && response.status < 300) {
      if (response.status === 201)
        notification.success({
          message: '操作成功',
          description: '创建记录成功！',
          duration: 1.5,
        })
      if (response.status === 204 && response.config.method === 'delete')
        notification.success({
          message: '操作成功',
          description: '删除记录成功！',
          duration: 1.5,
        })
      return Promise.resolve(response.data.data)
    } else {
      notification.error({
        message: '操作失败',
        description: '请求数据失败！',
        duration: 1.5,
      })
      return Promise.reject(response)
    }
  },
  async error => {
    if (error.response.status === 401) {
      // 静默刷新token
      // const data = await request.post('/token/refresh/', {
      //   refresh: localStorage.getItem('refresh_token'),
      // })
      // localStorage.setItem('token', data.access)

      localStorage.removeItem('token')
      history.replace('/auth/login')
      // return Promise.reject(error)
    }
    // if (error.response.status === 401) window.location.href = '/auth/login'
    if (error.response.status === 400 && !errorWhiteList.includes(error.response.config.url)) {
      notification.error({
        message: '操作失败',
        // description: '请求数据失败！',
        description: error.response.data.msg,
        duration: 1.5,
      })
    }

    return Promise.reject(error)
  }
)

export default request

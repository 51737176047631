// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2YQtxMpedyAoqPzT043E-G {\n  height: 61px;\n  border-bottom: 1px solid #e8e8e8;\n  box-shadow: 1px 1px 0 0 #e8e8e8;\n}\n.Aw0zD15UGt3j7PT7cUag- {\n  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);\n  background: #fff;\n}\n.Aw0zD15UGt3j7PT7cUag- .anticon {\n  color: #1da57a;\n}\n", "",{"version":3,"sources":["webpack://src/components/BasicLayout/SideMenu.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gCAAA;EACA,+BAAA;AACF;AAEA;EACE,2CAAA;EACA,gBAAA;AAAF;AAFA;EASM,cAAA;AAJN","sourcesContent":[".logo {\n  height: 61px;\n  border-bottom: 1px solid #e8e8e8;\n  box-shadow: 1px 1px 0 0 #e8e8e8;\n}\n\n.sider {\n  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);\n  background: #fff;\n  :global {\n    // .ant-layout-sider-trigger {\n    //   background: white;\n    // }\n\n    .anticon {\n      color: #1da57a;\n    }\n  }\n}\n\n@primary-color: #1DA57A;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "_2YQtxMpedyAoqPzT043E-G",
	"sider": "Aw0zD15UGt3j7PT7cUag-"
};
export default ___CSS_LOADER_EXPORT___;

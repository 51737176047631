// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2jPZMpTsZ_n5aJXN2GIsiX {\n  min-height: 100vh;\n}\n._2jPZMpTsZ_n5aJXN2GIsiX .fxZx-0qKEwJir7apNv5ts {\n  background: #fff;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  padding-left: 16px;\n  height: 61px;\n  justify-content: space-between;\n}\n._2jPZMpTsZ_n5aJXN2GIsiX .VPfAc18cmRVfDsvC8Eo7S ._1ctVpW5j1CR-daRqURwlyh {\n  padding: 16px;\n}\n._2jPZMpTsZ_n5aJXN2GIsiX ._3rPAcKeKEPz4iQSxafNBFg {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/BasicLayout/index.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAFA;EAGI,gBAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,8BAAA;AAEJ;AAXA;EAmBM,aAAA;AALN;AAdA;EAuBI,kBAAA;AANJ","sourcesContent":[".container {\n  min-height: 100vh;\n  .header {\n    background: #fff;\n    padding: 0;\n    display: flex;\n    align-items: center;\n    padding-left: 16px;\n    height: 61px;\n    justify-content: space-between;\n  }\n  .content {\n    // margin: 0px 16px;\n    // .breadcrumb {\n    //   margin: 16px 0;\n    // }\n    .appContainer {\n      // padding: 24px;\n      // background: #fff;\n      padding: 16px;\n    }\n  }\n  .footer {\n    text-align: center;\n    // background: #fff;\n  }\n}\n\n@primary-color: #1DA57A;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2jPZMpTsZ_n5aJXN2GIsiX",
	"header": "fxZx-0qKEwJir7apNv5ts",
	"content": "VPfAc18cmRVfDsvC8Eo7S",
	"appContainer": "_1ctVpW5j1CR-daRqURwlyh",
	"footer": "_3rPAcKeKEPz4iQSxafNBFg"
};
export default ___CSS_LOADER_EXPORT___;

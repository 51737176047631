import React from 'react'
import style from './index.module.less'
import { Link } from 'react-router-dom'
import { Layout } from 'antd'

export default function UserLayout({ children }) {
  return (
    <div className={style.container}>
      <div className={style.contentWrapper}>
        <div className={style.content}>
          <div className={style.top}>
            <div className={style.header}>
              {/* <img className={style.logo} src={logo} alt="" /> */}
              <Link to="/">
                <div className={style.title}>总务科考勤及巡查管理系统</div>
              </Link>
            </div>
          </div>
          {children}
        </div>
      </div>
      <Layout.Footer className={style.footer} style={{ color: '#969799', userSelect: 'none' }}>
        <span>©{new Date().getFullYear()} 协和医院总务科</span>
        {/* <span style={{ marginLeft: 10 }}>
          ICP备案号：
          <a target="__blank" href="http://www.beian.miit.gov.cn" style={{ color: '#969799' }}>
            备案号
          </a>
        </span> */}
      </Layout.Footer>
    </div>
  )
}

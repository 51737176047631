/* eslint-disable import/extensions */
import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import config from './config'
import NotFound from '@/components/NotFound'
import PageLoading from '@/components/PageLoading'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const RedirectAs404 = ({ location }) => (
  <Redirect
    to={{
      location: {
        ...location,
        state: { is404: true },
      },
    }}
  />
)

const { permissions = [] } = JSON.parse(window.localStorage.getItem('user')) || {}
const hasPerm = perms => {
  if (!perms || !perms.length) return true
  if (perms.some(item => !permissions.includes(item))) return false
  return true
}

// 受保护的Route
// 分为登陆 和 权限 两部分
// 登陆状态只需要判断localstorage中token的原因：假设即便用户自行在浏览器 设置token，那么手动刷新页面后，还是会因为初始的token验证失败而重定向到登陆页面
const ProtectedRoute = ({ location, perms, ...rest }) => {
  // TODO 路由里面必须加上Switch，不然这里location都是undeifine?

  // 如果开放性页面，则不鉴权，直接可以访问
  if (location.pathname.startsWith('/public'))
    return <Route location={location} perms={perms} {...rest} />

  // 其它页面都需要鉴权
  if (!localStorage.getItem('token') && !location.pathname.startsWith('/auth'))
    return (
      <Redirect
        to={{
          pathname: '/auth/login',
        }}
      />
    )

  // 如果此路由设置了访问权限，且用户不具有此权限，则返回401
  if (perms && perms.length > 0) {
    if (!hasPerm(perms)) return <Route component={NotFound} />
  }
  return <Route location={location} perms={perms} {...rest} />
}

// Route中的render props意思是只要此路由加载，就render该内容
// 配合Layout层级，来依次嵌套加载页面内容
// 例如
// BasicLayout 页面基础布局
//   PageCommon 具体页面的公共部分
//     Page 页面

// 一直递归渲染路由，直至当前路由为undefined
const renderRoutes = routes => {
  if (!Array.isArray(routes)) {
    return null
  }

  return (
    <Switch>
      {routes.map((route, index) => {
        if (route.redirect) {
          return (
            <Redirect
              key={route.path || index}
              exact={route.exact}
              strict={route.strict}
              from={route.path}
              to={route.redirect}
            />
          )
        }

        return (
          <ProtectedRoute
            key={route.path || index}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            perms={route.perms}
            render={props => {
              if (route.component) {
                return (
                  <Suspense fallback={<PageLoading />}>
                    <route.component route={route}>{renderRoutes(route.routes)}</route.component>
                  </Suspense>
                )
              }
              return renderRoutes(route.routes)
            }}
          />
        )
      })}
      {/* 在每一层路由列表的最后都加上未匹配的，重定向到404页面，保障即便匹配到父路由，也不会出现加载部分页面内容（父组件）的情况 */}
      <Route component={RedirectAs404} />
    </Switch>
  )
}

const AppRouter = () => {
  return (
    <Router history={history}>
      <Route
        render={({ location }) =>
          location.state && location.state.is404 ? (
            // 404时显示对应的404组件
            <NotFound />
          ) : (
            // 递归渲染可以用的router
            renderRoutes(config)
          )
        }
      />
    </Router>
  )
}

export default AppRouter

import React from 'react'
import { Layout, Typography } from 'antd'

export default () => {
  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      <Typography.Text type="secondary">©{new Date().getFullYear()}协和</Typography.Text>
    </Layout.Footer>
  )
}

/* eslint-disable import/extensions */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Layout, Menu, Typography } from 'antd'
import { useLocalStorageState } from 'ahooks'
import style from './SideMenu.module.less'
import { AppstoreTwoTone } from '@ant-design/icons'

// 根据路由渲染菜单
const renderMenuItem = routes => {
  return routes
    .filter(item => item.path && item.name && !item.hideInMenu)
    .map(subMenu => {
      if (
        // 有子路由 且子路由有有效的路由，就循环渲染
        subMenu.routes &&
        !!subMenu.routes.find(child => child.path && child.name)
      ) {
        return (
          <Menu.SubMenu
            key={subMenu.path}
            title={
              <span>
                {subMenu.icon && <subMenu.icon />}
                <span>{subMenu.name}</span>
              </span>
            }
          >
            {renderMenuItem(subMenu.routes)}
          </Menu.SubMenu>
        )
      }
      // 否则直接渲染此菜单
      // 对于没有子路由有没有component的路由 直接不渲染
      if (subMenu.component)
        return (
          <Menu.Item key={subMenu.path}>
            <Link to={subMenu.path}>
              <span>
                {subMenu.icon && <subMenu.icon />}
                <span>{subMenu.name}</span>
              </span>
            </Link>
          </Menu.Item>
        )
    })
}

const { permissions = [] } = JSON.parse(window.localStorage.getItem('user')) || {}

const hasPerm = perms => {
  if (!perms || !perms.length) return true
  if (perms.some(item => !permissions.includes(item))) return false
  return true
}

// 根据用户权限过滤需要显示的菜单
const filterRoutesByPermisson = originalRoutes => {
  if (!Array.isArray(originalRoutes)) {
    if (hasPerm(originalRoutes.perms)) {
      if (originalRoutes.routes) {
        let subroutes = originalRoutes.routes
          .filter(item => hasPerm(item.perms))
          .map(route => filterRoutesByPermisson(route))
        return {
          ...originalRoutes,
          routes: subroutes,
        }
      } else return originalRoutes
    }
  } else {
    return originalRoutes
      .filter(item => hasPerm(item.perms))
      .map(route => filterRoutesByPermisson(route))
  }
}

const SideMenu = ({ routes, appRoute, appTitle }) => {
  const { pathname } = useLocation()
  const [openKeys, setOpenKeys] = useState([])
  const [collapsed, setCollapsed] = useLocalStorageState('menu-collapsed', false)
  const prevOpenKeys = useRef([])

  // 路由变化时，变更openKeys
  useEffect(() => {
    const list = pathname.split('/').splice(1)

    const openKeys = list.map((item, index) => `/${list.slice(0, index + 1).join('/')}`)
    // 因为菜单的折叠状态采用了localstorage存储，这里就需要注意有一种情况就是
    // 页面进入时，菜单就是折叠状态，把应该展开的子菜单存起来备用即可
    if (!collapsed) setOpenKeys(openKeys)
    else {
      prevOpenKeys.current = openKeys
    }
  }, [collapsed, pathname])

  const getSelectedKeys = useMemo(() => {
    const list = pathname.split('/').splice(1)
    return list.map((item, index) => `/${list.slice(0, index + 1).join('/')}`)
  }, [pathname])

  const onOpenChange = keys => {
    setOpenKeys(keys)
  }

  // 在初次加载的时候获取可用的菜单路由
  const filteredRoutes = useMemo(() => {
    const r = filterRoutesByPermisson(routes)
    // console.log('filterd=', r)
    return r
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 解决收缩菜单式，子菜单弹窗显示的问题，也就是切换菜单收缩状态时，将选中的菜单保存起来
  // 收缩时将打开的子菜单置空，下次再切换的时候，再回填
  const onCollapse = collapse => {
    if (collapse) {
      prevOpenKeys.current = [...openKeys]
      setOpenKeys([])
    } else {
      setOpenKeys(prevOpenKeys.current)
    }
    setCollapsed(collapse)
  }
  return (
    <Layout.Sider
      // trigger={null}
      collapsible
      width={256}
      collapsed={collapsed}
      theme="light"
      onCollapse={onCollapse}
    >
      <div
        className={style.logo}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {collapsed && (
          <Link onClick={() => setCollapsed(false)}>
            {/* <img alt="" src={logo} width={30} /> */}
            <AppstoreTwoTone style={{ fontSize: 22 }} twoToneColor="#1DA57A" />
          </Link>
        )}

        {!collapsed && (
          <>
            {/* <Link
              to="/dashboard"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 10,
              }}
            >
              <AppstoreTwoTone style={{ fontSize: 22 }} twoToneColor="#1DA57A" />
            </Link> */}

            <a
              href="https://www.xhhq.top/landing"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 10,
              }}
            >
              <AppstoreTwoTone style={{ fontSize: 22 }} twoToneColor="#1DA57A" />
            </a>
            <Link to={appRoute} style={{ textAlign: 'center' }}>
              <Typography.Title level={4} style={{ marginBottom: 0, marginLeft: 0 }}>
                考勤及巡查管理
              </Typography.Title>
            </Link>
          </>
        )}
      </div>
      <Menu
        mode="inline"
        theme="light"
        style={{ paddingLeft: 0, marginBottom: 0 }}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        selectedKeys={getSelectedKeys}
      >
        {renderMenuItem(filteredRoutes)}
      </Menu>
    </Layout.Sider>
  )
}

export default SideMenu

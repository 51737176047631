import React from 'react'
import AuthLayout from '@/components/AuthLayout'
import BasicLayout from '@/components/BasicLayout'
import {
  DashboardOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  DeploymentUnitOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

export default [
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        name: '用户登陆',
        exact: true,
        component: React.lazy(() => import('@/pages/login')),
      },
    ],
  },
  {
    path: '/record-preview',
    name: '文档预览',
    icon: ProfileOutlined,
    exact: true,
    component: React.lazy(() => import('@/pages/record-preview')),
  },
  {
    path: '/',
    component: BasicLayout,
    routes: [
      {
        path: '/',
        exact: true,
        redirect: '/dashboard',
      },
      {
        path: '/dashboard',
        name: '数据概览',
        exact: true,
        icon: DashboardOutlined,
        component: React.lazy(() => import('@/pages/dashboard')),
      },
      {
        path: '/attendance',
        name: '考勤管理',
        icon: UnorderedListOutlined,
        routes: [
          {
            path: '/attendance/records',
            name: '考勤记录',
            exact: true,
            component: React.lazy(() => import('@/pages/attendance/record')),
          },
          {
            path: '/attendance/statistics',
            name: '数据统计',
            exact: true,
            component: React.lazy(() => import('@/pages/attendance/statistic')),
          },
        ],
      },
      {
        path: '/patrol',
        name: '巡查管理',
        icon: DeploymentUnitOutlined,
        routes: [
          {
            path: '/patrol/rounds',
            name: '巡查记录',
            exact: true,
            component: React.lazy(() => import('@/pages/patrol/round')),
          },
          {
            path: '/patrol/rounds/detail',
            name: '巡查列表',
            exact: true,
            hideInMenu: true,
            component: React.lazy(() => import('@/pages/patrol/record/list')),
          },
          {
            path: '/patrol/points',
            name: '巡查点位',
            exact: true,
            component: React.lazy(() => import('@/pages/patrol/point')),
          },
          {
            path: '/patrol/points/groups',
            name: '分组管理',
            exact: true,
            hideInMenu: true,
            component: React.lazy(() => import('@/pages/patrol/group')),
          },
        ],
      },
      {
        path: '/system',
        name: '系统管理',
        icon: SettingOutlined,
        routes: [
          {
            path: '/system/deps',
            name: '部门管理',
            exact: true,
            component: React.lazy(() => import('@/pages/dep')),
          },
          {
            path: '/system/users',
            name: '用户管理',
            exact: true,
            component: React.lazy(() => import('@/pages/user')),
          },
          {
            path: '/system/attendance-groups',
            name: '考勤分组',
            exact: true,
            component: React.lazy(() => import('@/pages/attendance/group')),
          },
          {
            path: '/system/form/list',
            name: '表单管理',
            exact: true,
            component: React.lazy(() => import('@/pages/form/list')),
          },
          {
            path: '/system/form/list/editor',
            name: '表单设计',
            exact: true,
            hideInMenu: true,
            component: React.lazy(() => import('@/pages/form/editor')),
          },
        ],
      },
    ],
  },
]
